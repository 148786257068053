import styles from "./App.module.css"
import { FiMenu } from "react-icons/fi"



export default () => {

	// TEST

	// let result = CONVERT_URL("https://drive.google.com/file/d/1mdYYet2T_m5yRP5aSs_SJ1D0JpYRtHmu/view?usp=share_link");
	// console.log({result})

	// navigator.vibrate && navigator.vibrate(1000)

	const [contact, setShowContact] = useState(false)
	global.SHOW_CONTACT = ()=>setShowContact(true)
	global.HIDE_CONTACT = ()=>setShowContact(false)
	const [showMenu, setShowMenu] = useState(false)


	// Router setup
	const [renders, setRenders] = useState(0)
	global.RERENDER = () => setRenders (renders + 1)
	window.onpopstate = RERENDER

	global.NAVIGATE = e => {
		history.pushState({}, '', e)
		
		window.scroll(0, 0)
		RERENDER()
	}

	global.PAGE = location.pathname



return (
<div className={styles.container}>

	{PAGE != "Contact" && 
		<div 
			style={{position: showMenu ? "fixed" : "absolute", left: 32, top: 32, zIndex: 13}}
			onClick={()=>setShowMenu(!showMenu)}>
			<FiMenu strokeWidth={3.5} color={showMenu ? "white" : "000098"} size={40}/>
		</div>}

	{(PAGE === "/" || !PAGE) && <Home/>}
	{PAGE === "/Management" && <Management/>}
	{PAGE === "/Elegance" && <Boat data={BOATS[0]}/>}
	{PAGE === "/Fairline" && <Boat data={BOATS[1]}/>}
	{PAGE === "/Princess" && <Boat data={BOATS[2]}/>}


	{contact && <ContactPage/>}
	{PAGE !== "Contact" && <Footer/>}
	{showMenu && <Menu close={()=>{setShowMenu(false)}}/>}

</div>)}