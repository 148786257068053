import styles from "./Slider.module.css"
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";


global.Slider = props => {


	let { images } = props
	const [selected, setSelected] = useState(0);
	const [wait, setWait] = useState(0)


	const nextClickHandler = () =>  {
		if (wait) return;
		if (selected < (images.length-1)) {
			setSelected(selected+1);
			setWait(true);
			setTimeout(()=>setWait(false), 750)}
		 }


return(

	<div className={styles.slide_container} 
	style={{
			aspectRatio: "auto",
			maxHeight: 600, 
			maxWidth: 1000, 
			position: "relative", overflow: "hidden",}}>

		<div className={styles.icon_container}>
			<div className={styles.icon_box} 
				style={{
					justifyContent: "flex-start",					
					transition: "all 0.5s", opacity: selected > 0 ? 1 : 0}}
				onClick={()=> {
					if (wait) return;
					if (selected > 0) {
						setSelected(selected-1);
						setWait(true);
						setTimeout(()=>setWait(false), 1000)}
						 }}>
				<FiChevronLeft size={70}/>
			</div>
			<div className={styles.icon_box}
				style={{
					justifyContent: "flex-end",
					transition: "all 1s", opacity: selected < (images.length-1) ? 1 : 0}}
					onClick={nextClickHandler}
						
			
						 
						 >
				<FiChevronRight size={70}/>
			</div>
		</div>

		{images.map((E,I) => 
			<img key={I} className={styles.image} 

		
				src={images[I]} style={{
					transition: "all ease-in-out 1000ms",
					position: "absolute",
					transform: I < selected ? "translateX(-100%)" :
							   I == selected ? "translateX(0%)" :
							   I > selected && "translateX(100%)"
					}}>
				</img>)
		}
			
		{/* We map the images again with opacity 0 and position: normal
		Because the visible images need to have position absolute which means
		They can give parent the size*/}

		{images.map((E,I) => 
			<img key={I} className={styles.image} style={{opacity: 0}} src={images[0]}/>)
		}

	</div>

)}
