import styles from "./Header_Body.module.css"


global.Header_Body = props => {


	let {title, body} = props

	let placeholderBody = "DA Line tarjoaa tilausristeilyjä super jahdeilla Helsingin saaristossa.  Jahtimme on matkan ajan sinun aluksesi - olemme palveluksessasi toteuttamassa hienoja elämyksiä."


return(
<div className={styles.container}>
	<div className={styles.title_container}>
		<h1>
			{title ?? "Title"}
		</h1>		
	</div>
	<div className={styles.body_container}>
		<p>
			{body ?? placeholderBody}
		</p>
	</div>
</div>
)}
