import styles from "./Boat.module.css"

let imagesPolish = [
	"/images/management/kiilto1.jpeg",
	"/images/management/kiilto2.jpeg"
]
let imagesTeak = [
	"/images/management/Teak.png",
	"/images/management/tiikki1.jpeg",
	"/images/management/tiikki 3.jpeg",
	"/images/management/tiikki 4.jpeg",
	"/images/management/tiikki5.jpeg",
	"/images/management/tiikki 6.jpeg",


]



let imagesMuut =   [
	"/images/management/muut6.jpg",
	"/images/management/muut1.jpg",
	"/images/management/muut5.jpg",
	"/images/management/muut7.jpg",

]


global.Management = props => {


	let prices = [
		{category: "Tiikkityöt alkaen", price: "59€/h"},
		{category: "Vahaukset, kiillotukset", price: "59€/h"},
		{category: "Muut huoltotyöt", price: "79€/h"}
	]


return(
<div className={styles.container}>

	<div className={styles.header_container}>
		<h1>Yacht Management</h1>
		{/* <p>Tarjoamme jahtien huolto ja kunnossapito palveluita vahvalla ammattiosaamisella ja kokemuksella.</p> */}
		<p style={{textAlign: "center", maxWidth: 600}}>Tarjoamme ympärivuotisesti jahtien huolto- ja kunnossapitopalveluita vahvalla ammattiosaamisella ja kokemuksella. Suoritamme pienistä huolloista aina suurten alusten kokonaisvaltaisiin refit-töihin, joissa suunnittelemme ja toteutamme projektit alusta loppuun itsenäisesti sekä yhteistyökumppaneidemme kanssa. </p>
	</div> 

	{/* <Slider images={imagesTeak}/> */}


	
	
	
	<div className={styles.content_container}>

	<Gap/>
	<Button text="Ota yhteyttä" onClick={()=>SHOW_CONTACT(true)}/>
	<Seperator space={32}/>	

		{/* <Gap size={12}/>
		<h3 style={{textAlign: "left", marginBottom: 0}}>Tiikki</h3>
		<div className={styles.spec_container}>
			<SpecRow icon={BiMapPin} name="Kotisatama" value="Helsinki, Katajanokka"/>
			<SpecRow icon={BsSpeedometer2} name="Matkavauhti" value="12 solmua"/>
			<SpecRow icon={IoBoatOutline} name="Pituus" value="26.6 m"/>
			<SpecRow icon={TbPropeller} name="Koneteho" value="2 x 1400hp"/>
			<SpecRow icon={RiTeamLine} name="Miehistö" value="3 (Kapteeni, kokki, tarjoilija)"/>		
		</div> */}


		<h3 style={{textAlign: "left", marginTop: 12}}>Tiikkityöt</h3>
		<p style={{textAlign: "left"}}>Onko tiikkikannet heikossa kunnossa? Usein tiikkikansissa on vielä paljon potentiaalia jäljellä ja ne vaativat vain kunnolloisen huollon. Suoritamme laadukkaita niin pienempiä kuin suurempiakin korjaustöitä ja entisöintejä kaikille veneiden puupinnoille.</p>
		<Gap size={12}/>
		<Slider images={imagesTeak}/>
		<Gap size={32}/>




		<h3 style={{textAlign: "left"}}>Kiillotus/pinnotukset</h3>
		<p style={{textAlign: "left"}}>Onko veneesi kiilto hävinnyt? Veneiden pinnat joutuvat usein koetuksille myös Suomessa. Suoritamme veneen maalipintojen vesihiontaa, kiillotusta sekä pinnotusta, jolloin veneesi pysyy pitkään kiiltävänä ja pinta likaahylkivänä. </p>
		<Gap size={12}/>
		<Slider images={imagesPolish}/>
		<Gap size={32}/>



		{/* <Seperator space={36}/>
		<Pricing data={prices}/>
		<Seperator space={36}/>
		<Button text="Ota yhteyttä"/>
		<Seperator space={36}/> */}


		<h3 style={{textAlign: "left"}}>Huoltotyöt</h3>
		<p style={{textAlign: "left"}}>Suoritamme yhdessä yhteistyökumppaneidemme kanssa kaikkien venemoottoreiden huoltoja, korjaustöitä, mahdollisia tehon lisäyksiä tai meikkauksia. Meillä on vahva kokemus konetekniikasta, venesähköistä sekä veneen muiden apulaitteiden korjauksesta.</p>
		<Gap size={12}/>
		<Slider images={imagesMuut}/>
		<Gap size={32}/>



		{/* <h3 style={{textAlign: "left"}}>Kokonaisvaltanen management</h3> */}

		{/* <Gap size={12}/>
		<Slider images={imagesTeak}/> */}




		<Seperator space={28}/>
		<Pricing data={prices} alv="24"/>
		<Seperator space={36}/>
		<Button text="Ota yhteyttä" onClick={()=>SHOW_CONTACT(true)}/>
		<Gap/>
		{/* <Seperator space={36}/> */}


	</div>

</div>
)}
