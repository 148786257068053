global.Services = props => {



	let placeholderDescr = "There will be days when the fishing is better than ones most optimistic forecast, others when it is far worse. Either is a gain over just staying home,” Roderick Haig Brown"



return(
<div className="container">

	<div className="service_section">
		
		<h1>Title</h1>
		<img></img>
		<div className="desc_container">
			<p>Description</p>
		</div>
		<Button text="Something"/>

	</div>

</div>
)}
