import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import "./index.css"


// 1. Enable hooks globally
import { useState, useEffect, useRef } from "react"
global.useState  = useState
global.useEffect = useEffect
global.useRef    = useRef


// 2. Import componets
import "./Components/Slider"
import "./Components/Pricing"
import "./Components/Menu" 
import "./Components/Card"
import "./Components/TextElements/Header_Body"
import "./Components/Seperator"
import "./Components/Button"
import "./Components/SpecRow"
import "./Components/Gap"
import "./Components/Toggle"
import "./Components/Footer"
import "./Components/Contact"
import "./Pages/Management"


// 3. Import pages
import "./Pages/Home"
import "./Pages/Boat"
import "./Pages/Services"
import "./Pages/Contact"


// 4. Import functions
import "./Functions/gdriveUrlConverter"


// 5. Import boat specs
import Elegance from "./Specs/Elegance.json"
import Fairline from "./Specs/Fairline.json"
import Princess from "./Specs/Princesse.json"
global.BOATS = [Elegance, Fairline, Princess]


// 7. Render app
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(<App/>)




global.PITCH_TEXT = `Koe unohtumaton jahti risteily luksusjahdilla. Voit keskittyä nauttimaan aluksemme ylellisyyksistä, sekä merellisistä maisemista yhdessä ystäviesi seurassa, kun miehistömme huolehtii teistä. Suomen kattavimmasta jahti tarjonnastamme löytyy varmasti sinun tarkoitukseesi sopiva.

DA Linen -luksusjahdeilla toteutamme haaveesi ja toiveesi. Voit valita erilaisia palveluita ja aktiviteetteja täydentämään elämystä. Olemme myös suunnitelleet merimatkalle halutessasi erilaisia ateriakokonaisuuksia suureen, tai pieneen nälkään. 

Matkusta Suomen upeimpiin merellisiin maisemiin. Voimme räätälöidä matkan reitin ja aikataulun tarpeidesi mukaan, olipa toiveissasi muutaman tunnin tai useamman päivän irtiotto. 

Kerro meille toiveistasi, niin me huolehdimme käytännön järjestelyistä. Meille on ensisijaisen tärkeää, että vieraanamme voit keskittyä nauttimaan jahdista, merimaisemista, seurasta ja luksuspalvelusta – tietysti täysin yksityisesti.`