import styles from "./Pricing.module.css"


global.Pricing = props => {

	let {alv} = props
	alv ??= "10"

	// Input data in format
	let placeholderData = [
		{category: "Arki päivä ristely 4h", price: "1.600€"},
		{category: "Arki-ilta ristely klo 18-24", price: "2.000€"},
		{category: "Viikonloppu", price: "6.400€"},
		{category: "Viikko", price: "12.400€"}
	]

	let data = props.data ?? placeholderData


return(
<div className={styles.container}>
	<h3 style={{textAlign: "left", marginBottom: 26, marginTop: 6}}>Hinnasto</h3>
	{data.map((e,i) => (

	<div key={i} className={styles.row_container}>
		<div className={styles.text_left}>
			{e.category}
		</div>
		<div className={styles.text_right}>
			{e.price}
		</div>
		
	</div>))}
	<div className={styles.footer_text}>
		{"Hintoihin lisätään arvonlisävero " + alv + "%"}
	</div>
</div>
)}
