import styles from "./Menu.module.css"

const menuElements2 = [
	{text: "ETUSIVU", page: "/"},
	{text: "ELEGANCE 70", page: "/Elegance"},
	{text: "PRINCESS 75", page: "/Princess"},
	{text: "FAIRLINE 62", page: "/Fairline"},
	{text: "YACHT MANAGEMENT", page: "/Management"},
]



// On mouse over


global.Menu = props => {


	const [howered, setHowered] = useState()


return (
<div className={styles.container}>
	<div className={styles.nav_container}>
		{menuElements2.map((E,I) => 
			<div 
				key={E.text}
				className={styles.menu_element}
				onClick={()=>{NAVIGATE(E.page); props.close()}}
				onMouseOver={()=>setHowered(I)}
				onMouseLeave={()=>setHowered(false)}
				
				style={{
					borderColor: (PAGE === E.page) ? "white" 
						: (howered === I) ? "white" 
						: "#000098",
					// width: I === menuElements2.length-1 && 400
				
				}}
			>
				{E.text}
			</div>
		)}
	</div>






	<div className={styles.footer_container}>
		<div className={styles.footer_title}>
			Ota yhteyttä
		</div>		
		<div className={styles.footer_number}>
			+358 50 917 5092
		</div>
		<div className={styles.footer_name}>
			OSKU KONTTINEN, CEO
		</div>
	</div>
	
</div>
)}
