


global.Gap = props => {

const { size } = props

return(
<div style={{height: size ?? 32}}>
</div>
)}
