import styles from "./Contact.module.css"


import { FiXCircle } from "react-icons/fi"





global.ContactPage = props => {






return(
<div className={styles.container}>
	<h1>Yhteydendotto</h1>
	<div className={styles.close_container} onClick={()=>HIDE_CONTACT(false)}>
		<FiXCircle/>
	</div>
	<div className={styles.content_container}>
		<Contact title={false} email={true}/>
	</div>

</div>
)}
