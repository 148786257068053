import styles from "./Seperator.module.css"


global.Seperator = props => {

	const { top, bottom, space, color } = props


return(
<div className={styles.container} style={{
	marginTop: top ?? space,
	marginBottom: bottom ?? space,
	backgroundColor: color
}}>
</div>
)}
