import styles from "./Toggle.module.css"


global.Toggle = props => {


let { options, maxWidth, onClick } = props

options ??= ["YACHT CHARTERS", "YACHT MANAGEMENT"]	

const [selected, setSelected] = useState(0)


return(
<div className={styles.container}
	style={{maxWidth: 600}}

>
	{options.map((e,i) => (
		<div
			key={i}
			onClick={()=>{setSelected(i); i == 1 && onClick && onClick(e)}}
			className={styles.side_container}
			style={{
				backgroundColor: selected === i ? "#000098" : "white",
				color: selected === i ? "white" : "#000098"
			
			}}
	
		>
			{e}
		</div>))}
	


</div>
)}
