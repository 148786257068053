import styles from "./Boat.module.css"
import { BsSpeedometer2 } from "react-icons/bs"
import { IoBoatOutline } from "react-icons/io5"
import { TbPropeller } from "react-icons/tb"
import { BiMapPin, BiBed } from "react-icons/bi"
import { RiTeamLine } from "react-icons/ri";


global.Boat = props => {


let data = props.data


return(
<div className={styles.container}>

	<div className={styles.header_container}>
		<h1>{data.specs.nimi}</h1>
		<p>{data.specs.kuvaus}</p>
	</div> 

	<Slider images={data.images.main}/>	
	 
	
	
	<div className={styles.content_container}>

		<Gap size={12}/>
		<h3 style={{textAlign: "left", marginBottom: 0}}>Ominaisuudet</h3>
		<div className={styles.spec_container}>
			<SpecRow icon={BiMapPin} name="Kotisatama" value={data.specs.kotisatama}/>
			<SpecRow icon={RiTeamLine} name="Matkustajat" value={data.specs.matkustajat}/>
			<SpecRow icon={BiBed} name="Makuupaikat" value={data.specs.matkuupaikat}/>
			<SpecRow icon={BsSpeedometer2} borderWidth={6} name="Matkavauhti" value={data.specs.matkavauhti}/>
			<SpecRow icon={IoBoatOutline} name="Pituus" value={data.specs.pituus}/>
			<SpecRow icon={TbPropeller} name="Koneteho" value={data.specs.koneteho}/>
			<SpecRow icon={RiTeamLine} name="Miehistö" value={data.specs.miehisto}/>		
		</div>


		<Seperator space={36}/>
		<Pricing data={data.prices}/>
		<Seperator space={36}/>
		<Button text="Ota yhteyttä" onClick={()=>SHOW_CONTACT(true)}/>
		<Seperator space={36}/>


		{/* <h3 style={{textAlign: "left"}}>Kuvaus</h3>
		<p style={{textAlign: "left"}}>{data.descriptions.yleis}</p>
		<Gap size={24}/>
		<Slider images={data.images.out}/> */}




		<h3 style={{textAlign: "left"}}>Ulkotilat</h3>
		<p style={{textAlign: "left"}}>{data.descriptions.ulko}</p>
		<Gap size={16}/>
		<Slider images={data.images.out}/>
		<Gap/>


		<h3 style={{textAlign: "left"}}>Sisätilat</h3>
		<p style={{textAlign: "left"}}>{data.descriptions.sisa}</p>
		<Gap size={16}/>
		<Slider images={data.images.inside}/>
		<Gap/>

		<Seperator space={36}/>


		<p style={{textAlign: "left"}}>{PITCH_TEXT}</p>

		<Seperator space={36}/>
		<Button text="Ota yhteyttä" onClick={()=>SHOW_CONTACT(true)}/>
		<Seperator space={36}/>


	</div>

</div>
)}
