import styles from "./Home.module.css"
import { useRef } from "react"


global.Home = props => {


	// This is it

	const text = "DA Line tarjoaa tilausristeilyjä jahdeilla Helsingin saaristossa. Jahtimme on matkan ajan sinun aluksesi - olemme palveluksessasi toteuttamassa ikimuistoisia elämyksiä."


	const [renders, setRenders] = useState(0);
	onresize = ()=>setRenders(renders+1)
	let height = window.innerWidth * (315/560)

	let [videoTouched, setVideoTouched] = useState(false)

	let url = "https://www.youtube.com/embed/Ou1Y83fDe58?start=34" 
	+ "&autoplay=1&mute=1"
	+ "&controls=0"
	+ "&modestbranding=1"

	+ "&showinfo=0"
	+ "&modestbranding=1"
	+ "&rel=0"		
	+"&iv_load_policy=3"


	// useEffect(setTimeout(()=> {
	// 	// document.getElementById('vid').play()
	// }, 1000), [])

	useEffect(()=>{

		// setTimeout(()=>document.getElementById('vid').play(), 100)
		// setTimeout(()=>document.getElementById('vid').play(), 500)

	},[])

	const videoContainer = useRef()

	// poster="images/fairline/6.JPG

	let inner = `
<video preload="auto" data-stylerecorder="true" autoplay muted playsinline loop>
	<source src="https://media.fraseryachts.com/web/content/videos/homepage/FORMOSA-yacht-for-charter-by-FRASER_home-page-Xk6G8lGk.m4v?vh=d15fa6&func=proxy" type="video/mp4" data-stylerecorder="true"/>
</video> `


let a = `<source src="videos/video2.mp4"  width="${"100%"}" height="${height + "px"}" type="video/mp4" data-stylerecorder="true">`

let inner2 = `
<video preload="auto" width="100%" data-stylerecorder="true" autoplay muted playsinline loop 
poster="https://cdn.nettivene.com/live/2022/07/05/9f63e42793d716b8-large.jpg"
<source src="videos/video2.mp4"  width="${"100%"}" height="${height + "px"}" type="video/mp4" data-stylerecorder="true">

">

</video> `

	useEffect(()=>{	
			console.log("Trying to inner", videoContainer.current)
			videoContainer.current.innerHTML = inner2
	}, [])




return(

	<div className={styles.container}>
		<div ref={videoContainer} style={{
			marginTop: 100, width: window.innerWidth,  height}}>
		</div>
    
		<div className={styles.content_container}>
				<h1 style={{marginTop: 40}}>
					{"DA LINE\nYACHT CHARTERS"}
				</h1>

		<p style={{paddingTop: 0}}>
					{text + "\n\nKerro meille toiveistasi - me huolehdimme käytännön järjestelyistä. Meille on ensisijaisen tärkeää, että vieraanamme voit keskittyä nauttimaan jahdista, merimaisemista, seurasta ja luksuspalveluista - tietysti täysin yksityisesti."}
				</p>

		<Gap size={60}/>
		<Toggle onClick={()=>setTimeout(()=>NAVIGATE("Management"), 850)}/>
		<Gap size={40}/>

	


		{BOATS.map((E,I) =>(
			<h3 key={I}>
				{E.specs.nimi}
				<Card data={E}/>
			</h3>

		))}

		<Gap size={80}/>

			
		</div>










	










		

	</div>


)}
