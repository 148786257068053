import styles from "./Contact.module.css"


global.Contact = props => {

	let {email, title} = props


return(
	<div className={styles.footer_container}>
		{title !== false && <div className={styles.footer_title}>
			Ota yhteyttä
		</div>}

		{email && 
			<div className={styles.footer_number}>
			info@daline.fi
			</div>}


		<div className={styles.footer_number}>
			<a 
			style={{color: "white"}}
			href="tel:+358509175092">+358 50 917 5092</a>
		
		</div>

		<div className={styles.footer_name}>
			OSKU KONTTINEN, CEO
		</div>
	</div>
)}
