import styles from "./SpecRow.module.css"
import { IoMdBed, IoIosBody } from "react-icons/io"
import { BiMapPin } from "react-icons/bi";
import { RiMoneyEuroCircleFill } from "react-icons/ri"


global.SpecRow = props => {

	
	let { icon, name, value } = props

	name ??= "Matkustajat"
	value ??= "8 kpl"

	let Icon = icon ??= IoMdBed


return(
<div className={styles.specs_row}>
	<div className={styles.icon_container}>
		<Icon/>
	</div>
	<div className={styles.specs_container}>
		<div className={styles.specs_text}>
			{name}
		</div>
		<div className={styles.specs_value}>
			{value}
		</div>
			</div>
		</div>
)}
