global.CONVERT_URL = url => {

		// https://drive.google.com/file/d/16uGrrijgAlhwCdjM6bUOI257ImI6Jv3t/view?usp=share_link

		// Match id part between d/ and /view...
		let regex = /d\/(.*)\/view/;
		let id = url.match(regex)[1];

		url = "https://drive.google.com/uc?id=" + id;

		return url;

}