import styles from "./Card.module.css"




import { IoMdBed, IoIosBody } from "react-icons/io"
import { BiMapPin } from "react-icons/bi";
import { RiMoneyEuroCircleFill } from "react-icons/ri"



global.Card = props => {


	let data = props.data
	

	let { image, specs, onClick } = props
	
	specs ??= [
		{icon: "Location", title: "Kotisatama", value: "Katajanokka"},
		{icon: "Person", title: "Matkustajat", value: "8 kpl"},
		{icon: "Bed", title: "Nukkumapaikat", value: "4 kpl"},
		{icon: "Price", title: "Hinta alkaen", value: "3800 €"}
	]
	
	
	
	return(
	<div className={styles.container}>

	
		<div className={styles.image_container}>

			<Slider images={data.images.main}/>

			{/* <img src={data.images.main[0]} className={styles.image_container}/> */}

		</div>
		<div className={styles.spec_container}>


				<div className={styles.specs_row}>
					<div className={styles.specs_icon}>
						<BiMapPin fill="#010097" size="30"/>
					</div>
					<div className={styles.specs_container}>
						<div className={styles.specs_text}>
							Kotisatama
						</div>
						<div className={styles.specs_value}>
							{data.specs.kotisatama}	
						</div>
					</div>
				</div>


				<div className={styles.specs_row}>
					<div className={styles.specs_icon}>
						<IoIosBody fill="#010097" size="30"/>
					</div>
					<div className={styles.specs_container}>
						<div className={styles.specs_text}>
							Matkustajat
						</div>
						<div className={styles.specs_value}>
							{data.specs.matkustajat}	
						</div>
					</div>
				</div>

				<div className={styles.specs_row}>
					<div className={styles.specs_icon}>
						<IoMdBed fill="#010097" size="32"/>
					</div>
					<div className={styles.specs_container}>
						<div className={styles.specs_text}>
							Makuupaikat
						</div>
						<div className={styles.specs_value}>
							{data.specs.makuupaikat}	
						</div>
					</div>
				</div>



				<div className={styles.specs_row}>
					<div className={styles.specs_icon}>
						<RiMoneyEuroCircleFill fill="#010097" size="30"/>
					</div>
					<div className={styles.specs_container}>
						<div className={styles.specs_text}>
							Hinta alkaen
						</div>
						<div className={styles.specs_value}>
							{data.specs.alkaenHinta}	
						</div>
					</div>
				</div>









			{/* {specs.map(E => (
				<div className={styles.specs_row}>
					<div className={styles.specs_icon}>
					{E.icon === "Location" &&
							<BiMapPin fill="#010097" size="30"/>}


						{E.icon === "Person" &&
							<IoIosBody fill="#010097" size="30"/>}

						{E.icon === "Bed" &&
							<IoMdBed fill="#010097" size="32"/>}

						{E.icon === "Price" &&
							<RiMoneyEuroCircleFill fill="#010097" size="30"/>}	



					</div>
					<div className={styles.specs_container}>
						<div className={styles.specs_text}>
							{E.title}
						</div>
						<div className={styles.specs_value}>
							{E.value}
						</div>
					</div>
				</div>
			))} */}
		</div>
	
		<div className={styles.button} onClick={()=>NAVIGATE(data.specs.page)}>
			Tutustu tarkemmin
		</div>
		
	
	</div>
	)}
	