import styles from "./Button.module.css"


global.Button = props => {

	let {text, filled, onClick} = props

	const [hower, setHower] = useState(false)



return(
<div 
	className={styles.container} 
	onClick={props.onClick}
	onMouseOver={()=>setHower(true)}
	onMouseLeave={()=>setHower(false)}
	style={{
		backgroundColor: hower ? "#000098" : "white",
	}}
	
	>
	<div
		style={{color: hower ? "white" : "#000098"}} 
		className={styles.text}>
	{text ?? "Button"}
	</div>
</div>
)}
