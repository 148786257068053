import styles from "./Footer.module.css"


global.Footer = props => {

	let pages = [
		{text: "Etusivu", page: "/"},
		{text: "Management", page: "/Management"},
		{text: "Elegance 70", page: "/Elegance"},
		{text: "Fairline 62", page: "/Fairline"},
		{text: "Princess 75", page: "/Princess"},
	]


	// let pages1 = [
	// 	{text: "Etusivu", page: "/Home"},
	// 	{text: "Management", page: "/Management"},
	// ]


const [hower, setHower] = useState(false)



return(
<div className={styles.container}>
	<div className={styles.title}>
		{"DA LINE\nYACHT CHARTERS"}
	</div>

	<div className={styles.navigation_container}
	>
		

		<div className={styles.list_container}
				>

			{pages.map(E=>(
				<div
					onMouseOver={()=>setHower(E.page)}
					key={E.text}
					className={styles.list_element}
					onClick={()=>NAVIGATE(E.page)}
					style={{borderColor: (PAGE === E.page || hower === E.page) ? 
						"white" : "#000098"}}
					>
						{E.text}
				</div>))}

		</div>


	


	</div>


	<Gap/>
	<Seperator color="white"/>
	<div className={styles.contact_container}>
		<Contact email={true}/>
	</div>



	{/* Contact us */}

	<Gap size={36}/>
	<div className={styles.copyright}>
		Copyright © DA Line Oy
	</div>

</div>
)}
